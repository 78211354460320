import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from 'react-query';
import { PostHogProvider } from 'posthog-js/react';

import './index.css';
import './styles/globals.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import CmsDataProvider from 'contexts/CmsDataProvider';

declare global {
  interface Window {
    dataLayer: Array<Record<string, any>>;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_POSTHOG_KEY}
    options={{
      api_host: process.env.REACT_APP_POSTHOG_HOST,
    }}
  >
    <QueryClientProvider client={queryClient}>
      <CmsDataProvider>
        <App />
      </CmsDataProvider>
    </QueryClientProvider>
  </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
